<template>
  <div>
    <div class="main">
      <div style="width:450px;display:flex;">
        <span style="line-height: 40px;font-size:16px">&nbsp;会员:&nbsp;</span><el-input  v-model="info" placeholder="会员姓名/卡号/手机号" clearable  />
      </div>
      <div style="font-size: 16px;">
        <span>&nbsp;店铺名称:&nbsp;</span>
        <el-select v-model="shopValue" @change='shopChange' placeholder="店铺名称">
          <el-option
            v-for="item in shopList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div style="font-size: 16px;">
        <span>&nbsp;&nbsp;会员卡种:&nbsp;</span>
        <el-select v-model="cardListValue" @change='cardChange' placeholder="会员卡种">
          <el-option
            v-for="item in cardList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <el-button  style="font-size: 16px;" @click="reqTableData" type="primary">查询</el-button>
    </div>
    <div class="vip-data-list" style="font-size:15px">会员总人数：{{numM}} 人&nbsp;&nbsp;（先生：{{man}}人&nbsp;&nbsp;女士：{{woman}}人&nbsp;&nbsp;不详：{{un}}人）</div>
    <div>
      <el-table 
        :cell-style="textStyle" 
        :data="tableVipData" 
        stripe style="width: 95%;margin:0 auto"
        @cell-click="clickFun"
      >
        <el-table-column height=60 prop="createTime" label="入会时间" width="180" />
        <el-table-column prop="memberName" label="会员姓名"/>
        <el-table-column prop="memberSex" label="性别" />
        <el-table-column prop="memberCardNumber" label="会员卡号" />
        <el-table-column prop="memberPhone" label="手机号" />
        <el-table-column prop="cardName" label="卡片类型" />
        <el-table-column prop="check" label="详情" />
      </el-table>
    </div>
    <div class="paging">
      <div style="margin:auto;float: right;">
        <el-pagination 
          background 
          @current-change='pagingReq' 
          layout="prev, pager, next" 
          :current-page="pagNum"
          :total="pagingNum">
        </el-pagination>
      </div>
    </div>
    <Print rul='report/exportMemberDetail.json' :add='printAdd' />
  </div>
</template>

<script>
import {ref} from'vue'
import {useRouter} from'vue-router'
import {postData} from'@a'
export default {
  setup() {
    let router = useRouter()
    let shopList = ref([])
    let cardList = ref([])
    let printAdd = ref('')
    let cardListValue = ref('全部会员')
    let shopValue = ref('全部店铺')
    let info = ref('')
    let tableVipData = ref([])
    let numM = ref(0)
    let man = ref(0)
    let woman = ref(0)
    let un = ref(0)
    const textStyle = ({ row, column })=>{
      if(!row)console.log(1)
      let style={}
      style['font-size'] = '14px'
      if(column.property === 'check' && column.label === '详情'){
        style.cursor= 'pointer'
        style.color = '#619BD8'
      }
      return style
    }
    let param = {
      shopIds:0,
      pageNo:1,
      pageSize:13
    }
    const clickFun = (row, column)=>{
      if(!row)console.log(1)
      if(column.label==="详情"){
        router.push({name:'vipDetailPage',query:{shopId:row.shopId,memberId:row.memberId,cardName:row.cardName}})
      }
    }
    let reqShopId = 0
    let reqcardId = 0
    const shopChange = (val)=>{
      reqShopId = val
      reqcardId = 0
      cardListValue.value = '全部会员'
      infoCardList({shopIds:val})
    }
    const cardChange = (val)=>{
      if(val !== '全部会员'){
        reqcardId = val
      }else{
        reqcardId = 0
      }
    }
    let pagingNum = ref(10) //分页数
    let pagNum = ref(1)       //当前页
    const pagingReq = (num)=>{  //分页请求
      pagNum.value = num
      param.pageNo = num
      infoTable(param)
    }
    const reqTableData = ()=>{
      if(reqShopId !== 0){
        param.shopIds = reqShopId

      }
      if(reqcardId !== 0){
        param.cardId = reqcardId
      }else if(param.cardId){
        delete param.cardId
      }
      infoTable(param)
    }
    const infoCardList = async (param) => {
      try {
        let res = await(postData('report/memberCardType.json',param))
        cardList.value = []
        for(let i =0;i<res.data.length;i++){
          res.data[i].value = res.data[i].cardId
          res.data[i].label = res.data[i].cardName
        }
        res.data.unshift({value:'全部会员',laber:'全部会员'})
        cardList.value = res.data
      } catch (err) {
        console.log(err)
      }
    }
    if(window.sessionStorage.getItem('cardList')){
      let data = JSON.parse(window.sessionStorage.getItem('shopList'))
      for(let i =0;i<data.length;i++){
        data[i].value = data[i].shopId
        data[i].label = data[i].shopName
      }
      data.unshift({value:'',label:'全部店铺'})
      shopList.value=data
      for(let i = 1;i<shopList.value.length;i++){
        if(i>1) shopList.value[0].value = shopList.value[0].value+','
        shopList.value[0].value = shopList.value[0].value + shopList.value[i].shopId
      }
    }
    if(window.sessionStorage.getItem('cardList')){
      cardList.value = JSON.parse(window.sessionStorage.getItem('cardList'))
    }
    const infoTable = async (param) => {
      try {
        if(info.value != ''){
          param.q = info.value
        }else if(param.q){
          delete param.q
        }
        numM.value = 0
        man.value = 0
        woman.value = 0
        un.value = 0
        let res = await(postData('report/memberDetail.json',param))
        numM.value = +res.data.man + +res.data.woman + +res.data.un
        man.value = res.data.man
        woman.value = res.data.woman
        un.value = res.data.un
        tableVipData.value = res.data.rows.records
        pagingNum.value = res.data.rows.total/13*10
        printAdd.value = '&pageSize='+ res.data.rows.total
        printAdd.value += '&shopIds='+ param.shopIds
        if(param.cardId){
          printAdd.value += '&cardId='+param.cardId
        }
        if(param.q){
          printAdd.value += '&q='+param.q
        }
        tableVipData.value.forEach((e) => {
          e.memberSex = e.memberSex==0?'未知':e.memberSex==1?'男':'女'
          e.check = '查看'
        });
      } catch (err) {
        console.log(err)
      }
    }
    console.log(shopList.value)
    for(let i = 1;i<shopList.value.length;i++){
      if(i>1) param.shopIds = param.shopIds+','
      param.shopIds = param.shopIds+shopList.value[i].shopId
    }
    infoTable(param)
    return{
      reqTableData,
      shopChange,
      cardChange,
      tableVipData,
      textStyle,
      clickFun,
      pagingNum,
      pagNum,
      pagingReq,
      shopList,
      shopValue,
      info,
      cardList,
      cardListValue,
      printAdd,
      numM,
      man,
      woman,
      un,
    }

  }

}
</script>

<style scoped>
.vip-data-list{
  background-color: #D9EFFF;
  height: 65px;
  line-height: 65px;
  padding-left: 25px;
  font-size: 16px;
  font-weight: 600;
}

.paging>div{
  margin:auto;
  /* position: absolute; */
  /* left: 50%; */
  /* bottom: 40px; */
  /* transform: translateX(-50%); */
  opacity: 0.6;
}
.main{
  display:flex;
  margin:20px 0;
  flex-wrap: wrap;
}
.main>div{
  margin-right: 20px;
}
</style>